import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const config = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: "ergoapp-19c35.firebaseapp.com",
  databaseURL: "https://ergoapp-19c35.firebaseio.com",
  projectId: "ergoapp-19c35",
  storageBucket: "ergoapp-19c35.appspot.com",
  messagingSenderId: "470607538959",
  appId: "1:470607538959:web:96af79172c743f33c9ac7a",
  measurementId: "G-W3YYCB39M9",
};

firebase.initializeApp(config);
firebase.firestore();

export default firebase;
