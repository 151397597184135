import React from "react";
import styled from "styled-components";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import Terms from "./terms/Terms";
import FB from "../../static/images/fb.png";
import Instagram from "../../static/images/instagram.png";
import Linkedn from "../../static/images/linkedin.png";
import Twitter from "../../static/images/twitter.png";
import YouTube from "../../static/images/youtube.png";
import Whatsapp from "../../static/images/whatsapp.png";

const FooterDiv = styled.div`
  width: 100%;
  height: auto;
  margin: 0px 0px 0px 0px;
  clear: both;
  padding-top: 24px;
  padding-bottom: 24px;
  text-align: center;
  background-image: linear-gradient(
    to right,
    #183880,
    #254f94,
    #3567a8,
    #487fba,
    #5d97cc
  );
`;

const Bold = styled.b`
  font-weight: 600;
`;

const Wrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const IconWrapper = styled.div`
  width: 60%;
  display: inline-flex;
`;

const ImgIcon = styled.img`
  width: 20px;
  height: auto;
`;

const SocialLinks = () => {
  return (
    <React.Fragment>
      <Grid item xs={2}>
        <Link
          color="secondary"
          variant="body1"
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.facebook.com/ErgoSolar"
        >
          <ImgIcon src={FB} alt="ErgoSolar icon facebook" />
        </Link>
      </Grid>
      <Grid item xs={2}>
        <Link
          color="secondary"
          variant="body1"
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.instagram.com/ergosolar.mx/"
        >
          <ImgIcon src={Instagram} alt="ErgoSolar icon instagram" />
        </Link>
      </Grid>
      <Grid item xs={2}>
        <Link
          color="secondary"
          variant="body1"
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.linkedin.com/company/ergosolar/"
        >
          <ImgIcon src={Linkedn} alt="ErgoSolar icon linkedin" />
        </Link>
      </Grid>
      <Grid item xs={2}>
        <Link
          color="secondary"
          variant="body1"
          rel="noopener noreferrer"
          target="_blank"
          href="https://twitter.com/ErgoSolar"
        >
          <ImgIcon src={Twitter} alt="ErgoSolar icon twitter" />
        </Link>
      </Grid>
      <Grid item xs={2}>
        <Link
          color="secondary"
          variant="body1"
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.youtube.com/channel/UCTZMuy53nPoLu8R3g5zUbhA"
        >
          <ImgIcon src={YouTube} alt="ErgoSolar icon youtube" />
        </Link>
      </Grid>
      <Grid item xs={2}>
        <Link
          color="secondary"
          variant="body1"
          rel="noopener noreferrer"
          target="_blank"
          href="https://wa.me/522227094826"
        >
          <ImgIcon src={Whatsapp} alt="ErgoSolar icon whatsapp" />
        </Link>
      </Grid>
    </React.Fragment>
  );
};

const Footer = () => {
  return (
    <>
      <FooterDiv>
        <Container>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Wrapper>
                <Terms check />
                <Link
                  color="secondary"
                  variant="body1"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.ergosolar.mx/aviso-privacidad/"
                >
                  Aviso de privacidad
                </Link>
                <br />
                <br />
                <Typography color="secondary" variant="body1" gutterBottom>
                  <Bold>
                    &copy; {new Date().getFullYear()} ErgoSolar - ErgoApp
                  </Bold>
                </Typography>
              </Wrapper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Wrapper>
                <Typography color="secondary" variant="body1" gutterBottom>
                  <Bold>Contáctanos</Bold>
                </Typography>
                <Link
                  color="secondary"
                  variant="body1"
                  href="tel:+52 (227) 271 51 01"
                >
                  +52 (227) 271 51 01
                </Link>
                <br />
                <Link
                  color="secondary"
                  variant="body1"
                  href="tel:+52 (227) 271 51 05"
                >
                  +52 (227) 271 51 05
                </Link>
                <br />
                <Link
                  color="secondary"
                  variant="body1"
                  href="mailto:optimiza@ergosolar.mx"
                >
                  optimiza@ergosolar.mx
                </Link>
                <br />
                <Link
                  color="secondary"
                  variant="body1"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://ergosolar.mx"
                >
                  www.ergosolar.mx
                </Link>

                <Grid container item xs={12}>
                  <Wrapper>
                    <IconWrapper>
                      <SocialLinks />
                    </IconWrapper>
                  </Wrapper>
                </Grid>
              </Wrapper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Wrapper>
                <Typography color="secondary" variant="body1" gutterBottom>
                  <Bold>¿Dónde estamos?</Bold>
                </Typography>
                <br />
                <Typography color="secondary" variant="body1">
                  <Bold>Blvd Manuel Cabañas Pavía #9A.</Bold>
                </Typography>
                <Typography color="secondary" variant="body1">
                  <Bold>Fraccionamiento Aeroparque,</Bold>
                </Typography>
                <Typography color="secondary" variant="body1">
                  <Bold>Huejotzingo, Puebla. C.P. 74160.</Bold>
                </Typography>
              </Wrapper>
            </Grid>
          </Grid>
        </Container>
      </FooterDiv>
    </>
  );
};

export default Footer;
