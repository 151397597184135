import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import ErgoApp_1 from "../../../static/images/ergoapp2.png";
import ErgoApp_2 from "../../../static/images/ergoapp3.png";
import ErgoApp_3 from "../../../static/images/ergoapp4.png";
import ErgoTrust from "../../../static/images/ergotrust.png";
import Ahorro from "../../../static/images/ahorro.png";
import Comunidad from "../../../static/images/comunidad.png";

const stylesRows = makeStyles((theme) => ({
  item2: {
    order: 3,
    [theme.breakpoints.up("sm")]: {
      order: 2,
    },
  },

  item3: {
    order: 2,
    [theme.breakpoints.up("sm")]: {
      order: 3,
    },
  },
}));

const Wrapper = styled.div`
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
`;

const Image = styled.img`
  width: 60%;
  height: auto;
  margin-bottom: 1rem;
`;

const Bold = styled.b`
  font-weight: 600;
`;

const IconFont = styled.img`
  width: 50px;
  margin-right: 1rem;
  vertical-align: middle;
`;

const ContentCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Functions = () => {
  const classes = stylesRows();

  return (
    <>
      <Wrapper>
        {/*#region ErgoTrust*/}
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <Image src={ErgoApp_1} alt="ErgoTrust ErgoSolar" />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ContentCenter>
              <Typography color="textPrimary" variant="h4" gutterBottom>
                <IconFont src={ErgoTrust} alt="icono ergotrust ergosolar" />
                <Bold>Vive la ErgoTrust</Bold>
              </Typography>
              <Typography color="textSecondary" variant="h5" gutterBottom>
                <Bold>
                  Acompañamiento y control
                  <br />
                  en el desarrollo de tu proyecto.
                </Bold>
              </Typography>
            </ContentCenter>
          </Grid>
        </Grid>
        {/*#endregion*/}

        {/*#region Ahorro*/}
        <Grid container>
          <Grid item xs={12} sm={12} md={6} className={classes.item2}>
            <ContentCenter>
              <Typography color="textPrimary" variant="h4" gutterBottom>
                <IconFont src={Ahorro} alt="icono ahorro ergosolar" />
                <Bold>Visualiza tu ahorro</Bold>
              </Typography>
              <Typography color="textSecondary" variant="h5" gutterBottom>
                <Bold>
                  Tus ahorros mensuales y
                  <br />
                  acumulados con energía solar.
                </Bold>
              </Typography>
            </ContentCenter>
          </Grid>
          <Grid
            item
            row-reverse-xs={12}
            sm={12}
            md={6}
            className={classes.item3}
          >
            <Image src={ErgoApp_3} alt="Ahorros ErgoSolar" />
          </Grid>
        </Grid>
        {/*#endregion*/}

        {/*#region Comunidad*/}
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <Image src={ErgoApp_2} alt="Comunidad ErgoSolar" />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ContentCenter>
              <Typography color="textPrimary" variant="h4" gutterBottom>
                <IconFont src={Comunidad} alt="icono comunidad ergosolar" />
                <Bold>Comunidad ErgoSolar</Bold>
              </Typography>
              <Typography color="textSecondary" variant="h5" gutterBottom>
                <Bold>
                  Descubre quiénes ahorran
                  <br />
                  cerca de ti. Refiérenos y gana.
                </Bold>
              </Typography>
            </ContentCenter>
          </Grid>
        </Grid>
        {/*#endregion*/}
      </Wrapper>
    </>
  );
};

export default Functions;
