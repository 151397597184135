import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";

import { TextField, CheckboxWithLabel } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Terms from "../../../components/footer/terms/Terms";
import * as actions from "../../../store/actions";

const stylesForm = makeStyles((theme) => ({
  textField: {
    width: "100%",
    marginBottom: "1rem",
  },
  formWrapper: {
    width: "90%",
    margin: "0 auto",
    maxWidth: "25rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    margin: "1rem",
  },
  div: {
    marginTop: "1rem",
    marginBottom: "0.5rem",
  },
}));

const RegisterForm = ({ signUp, loading, error, cleanUp }) => {
  const handleLoaded = (_) => {
    window.grecaptcha.ready((_) => {
      window.grecaptcha
        .execute(`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`, {
          action: "homepage",
        })
        .then((token) => {});
    });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    script.addEventListener("load", handleLoaded);
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, [cleanUp]);

  const classes = stylesForm();

  return (
    <>
      <Formik
        initialValues={{
          name: "",
          email: "",
          phone: "",
          password: "",
          confirmPassword: "",
          terms: false,
        }}
        validate={(values) => {
          const errors = {};

          if (!values.name) {
            errors.name = "*Requerido";
          }

          if (!values.email) {
            errors.email = "*Requerido.";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Por favor, ingresa un correo electrónico válido.";
          }

          if (values.phone && values.phone.length < 10) {
            errors.phone = "Por favor, ingresa un teléfono a 10 dígitos.";
          }

          if (!values.password) {
            errors.password = "*Requerido.";
          } else if (values.password.length < 8) {
            errors.password =
              "La contraseña debe contener al menos 8 caracteres.";
          }

          if (!values.confirmPassword) {
            errors.confirmPassword = "*Requerido.";
          } else if (values.password !== values.confirmPassword) {
            errors.confirmPassword = "Las contraseñas no coinciden";
          } else if (values.confirmPassword.length < 8) {
            errors.confirmPassword =
              "La contraseña debe contener al menos 8 caracteres.";
          }

          if (!values.terms) {
            errors.terms = "*Requerido";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            //alert(JSON.stringify(values, null, 2));
            signUp(values);
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <div className={classes.formWrapper}>
            <Form onSubmit={handleSubmit} className={classes.form}>
              <Field
                component={TextField}
                label="Nombre completo"
                type="text"
                name="name"
                className={classes.textField}
              />
              <Field
                component={TextField}
                label="Correo electrónico"
                type="email"
                name="email"
                className={classes.textField}
              />
              <Field
                component={TextField}
                label="Teléfono"
                type="phone"
                name="phone"
                className={classes.textField}
              />
              <Field
                component={TextField}
                label="Contraseña"
                type="password"
                name="password"
                className={classes.textField}
              />
              <Field
                component={TextField}
                label="Confirma tu contraseña"
                type="password"
                name="confirmPassword"
                className={classes.textField}
              />
              <Field
                component={CheckboxWithLabel}
                Label={{ label: <Terms isBlue={true} /> }}
                color="primary"
                type="checkbox"
                name="terms"
              />
              <div className={classes.div}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                >
                  Limpiar formulario
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  data-sitekey="reCAPTCHA_site_key"
                  data-callback="onSubmit"
                  data-action="submit"
                  disabled={isSubmitting || !isValid || loading}
                >
                  {loading ? "Registrándote" : "Registrame"}
                </Button>
              </div>
              <Typography color="error" variant="body2" gutterBottom>
                {error}
              </Typography>

              <Typography color="primary" variant="body1" gutterBottom>
                <b>{error === false ? "Registro completado" : null}</b>
              </Typography>

              <Typography color="textSecondary" variant="body1" gutterBottom>
                Al terminar tu registro, podrás ingresar a ergoapp dando clic en
                la siguiente imágen:
              </Typography>
              <a href="https://play.google.com/store/apps/details?id=com.ergosolar.ergoapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  width="60%"
                  alt="Disponible en Google Play"
                  src="https://play.google.com/intl/es-419/badges/static/images/badges/es-419_badge_web_generic.png"
                />
              </a>
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  loading: auth.loading,
  error: auth.error,
});

const mapDispatchToProps = {
  signUp: actions.SignUp,
  cleanUp: actions.Clean,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
