import React from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import Navbar from "../../navigation/navbar/Navbar";
import Banner from "../../../containers/home/banner/Banner";
import { theme } from "../theme/Theme";
import Footer from "../../footer/Footer";

const Layout = ({ children }) => {
  return (
    <>
      <MuiThemeProvider theme={theme}>
        <Navbar />
        <Banner />
        <Container>{children}</Container>
        <Footer />
      </MuiThemeProvider>
    </>
  );
};

export default Layout;
