import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#183880",
      light: "#F76E36",
    },
    secondary: {
      main: "#FFF",
      light: "#F76E36",
    },
    text: {
      primary: "#183880",
      secondary: "rgba(0,0,0,.6)",
      hint: "#FFF",
    },
  },
});
