import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Invitado from "../../../static/images/invitado.webp";
import Cliente from "../../../static/images/cliente.jpg";

const Wrapper = styled.div`
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
`;

const Image = styled.img`
  width: 90%;
  height: auto;
  margin-bottom: 1rem;
`;

const Bold = styled.b`
  font-weight: 600;
`;

const Ul = styled.ul`
  text-align: left;
  list-style: none;
  margin: 2em;
  padding: 0;

  li {
    margin: 0;
    margin-bottom: 1em;
    padding-left: 1.5em;
    position: relative;

    &:after {
      content: "";
      height: 0.5em;
      width: 0.5em;
      background-image: linear-gradient(
        to right,
        #e9802f,
        #e8762f,
        #e76b30,
        #e66031,
        #e45533
      );
      display: block;
      position: absolute;
      transform: rotate(45deg);
      top: 0.35em;
      left: 0;
    }
  }
`;

const Users = () => {
  return (
    <>
      <Wrapper>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <Image src={Cliente} alt="ErgoSolar ErgoApp Cliente" />
            <Typography color="textPrimary" variant="h4">
              <Bold>Cliente - Ya soy solar</Bold>
            </Typography>
            <Ul>
              <li>
                <Typography color="textSecondary" variant="body1" gutterBottom>
                  Con ErgoTrust visualiza cada paso de tu proyecto.
                </Typography>
              </li>
              <li>
                <Typography color="textSecondary" variant="body1" gutterBottom>
                  Controla tus ahorros solares mes con mes.
                </Typography>
              </li>
              <li>
                <Typography color="textSecondary" variant="body1" gutterBottom>
                  Contacta directamente con tu anfitrión de proyecto.
                </Typography>
              </li>
              <li>
                <Typography color="textSecondary" variant="body1" gutterBottom>
                  Recibe alertas de mantenimientos y revisiones.
                </Typography>
              </li>
              <li>
                <Typography color="textSecondary" variant="body1" gutterBottom>
                  Forma parte de nuestra comunidad ErgoSolar y obtén beneficios.
                </Typography>
              </li>
            </Ul>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Image src={Invitado} alt="ErgoSolar ErgoApp Invitado" />
            <Typography color="textPrimary" variant="h4">
              <Bold>Soy invitado</Bold>
            </Typography>
            <Ul>
              <li>
                <Typography color="textSecondary" variant="body1" gutterBottom>
                  Infórmate y actualízate sobre la energía en México y así poder
                  tomar decisiones inteligentes para tu negocio, empresa o
                  industria.
                </Typography>
              </li>
              <li>
                <Typography color="textSecondary" variant="body1" gutterBottom>
                  Calcula el posible ahorro si generas tu propia energía con el
                  sol.
                </Typography>
              </li>
              <li>
                <Typography color="textSecondary" variant="body1" gutterBottom>
                  Conviértete en embajador solar y obtén beneficios, sólo por
                  hablar de nosotros.
                </Typography>
              </li>
            </Ul>
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
};

export default Users;
