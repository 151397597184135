import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";

import RegisterForm from "./form/RegisterForm";
import Video from "./video/Video";
import Users from "./users/Users";
import Functions from "./functions/Functions";

const Layout = styled.div`
  width: 100%;
  text-align: center;
`;

const Bold = styled.b`
  font-weight: 600;
`;

const Home = () => {
  return (
    <>
      <Layout>
        <Typography
          color="textPrimary"
          variant="h4"
          component="h1"
          gutterBottom
        >
          <Bold>
            Conecta con la energía solar
            <br />
            más segura del mundo.
          </Bold>
        </Typography>
        <Typography color="textSecondary" variant="h6" gutterBottom>
          <Bold>
            Aplicación móvil para clientes de ErgoSolar e invitados,
            <br />
            donde conocerás todo sobre la energía solar y tu sistema.
          </Bold>
        </Typography>

        <RegisterForm />
        <Video />
        <Users />
        <Functions />
      </Layout>
    </>
  );
};

export default Home;
