import React from "react";
import styled from "styled-components";

import ImgBanner from "../../../static/images/banner.webp";

const Image = styled.img`
  margin-top: 3.5rem;
  width: 100%;
  height: auto;
  margin-bottom: 2rem;
`;

const Banner = () => {
  return (
    <>
      <Image alt="ErgoSolar ErgoApp" src={ImgBanner} />
    </>
  );
};

export default Banner;
