import * as actions from "./actionTypes";

//SIGN UP ACTIONS
export const SignUp = (data) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();
  const url = window.location.href;

  let arraySpl = url.split("/");
  let newUrl = "?" + arraySpl[3].toString();

  let params = new URLSearchParams(newUrl);
  let idRefer = params.get("userId");

  dispatch({ type: actions.AUTH_START });

  try {
    const res = await firebase
      .auth()
      .createUserWithEmailAndPassword(data.email, data.password);

    const user = firebase.auth().currentUser;

    await user.sendEmailVerification();

    await firestore
      .collection("users")
      .doc(res.user.uid)
      .set({
        email: data.email,
        fullName: data.name,
        idClient: null,
        imageURL: null,
        linkRefer: "",
        notifications: {
          news: true,
          tips: true,
          unsubscribe: false,
        },
        phone: data.phone !== "" ? data.phone : "",
        role: "invite",
        useLink: false,
      });

    if (idRefer !== null && idRefer !== "") {
      let invites = await firestore.collection("invites").doc(idRefer).get();
      let userData = await firestore
        .collection("users")
        .doc(res.user.uid)
        .get();

      let useLink = userData.data()["useLink"];

      if (useLink === false) {
        const newRefer = {
          id: res.user.uid,
          name: data.name,
          status: "En espera",
          benefit: "0 pts",
        };

        if (invites !== null) {
          await firestore
            .collection("invites")
            .doc(idRefer)
            .set({
              referidos: [newRefer],
            });

          await firestore.collection("users").doc(res.user.uid).update({
            useLink: true,
          });
        }
      }
    }

    dispatch({ type: actions.AUTH_SUCCESS });
  } catch (error) {
    dispatch({ type: actions.AUTH_FAIL, payload: error.message });
  }

  dispatch({ type: actions.AUTH_END });
};

// Clean messages

export const Clean = () => ({
  type: actions.CLEAN_UP,
});
