import React from "react";
import Layout from "./components/ui/layout/Layout";

import Home from "./containers/home/Home";

const App = () => {
  return (
    <>
      <Layout>
        <Home />
      </Layout>
    </>
  );
};

export default App;
